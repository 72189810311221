import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

import match from '@/components/match.vue'
import sign from '@/components/sign.vue'
import NotFound from '@/components/404.vue'

const routes = [
  {
    path: '/',
    name: 'match',
    redirect: '/match'
  },
  {
    name: 'match',
    path: '/match/:signdiscountid?',
    component: match,
  },
  {
    name: 'sign',
    path: '/sign',
    component: sign,
  },
  {
    path: '*',
    component: NotFound
  },
];

const router = new Router({
  mode: "history",
  routes,
  base: "/",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  console.log(1, from);
  console.log(2, to);
  let { signdiscountid } = to.params;
  if (signdiscountid) {
    localStorage.setItem("zzdxs.signdiscountid", signdiscountid);
  }
  let { wcid } = to.query;
  if (wcid) localStorage.setItem('zzdxs.wcid', wcid);
  wcid = localStorage.getItem('zzdxs.wcid');
  if (!wcid || wcid.indexOf('null') > -1 || wcid.indexOf('undefined') > -1) {
    return window.location.replace(`${process.env.BASE_API}/oauth?redirect_url=${encodeURIComponent(window.location.href)}`);
  }
  if (to.name === 'match' && signdiscountid) {
    next({ path: "/match" });
  } else {
    next();
  }
});
window._doRouterPush = (path) => router.push({ path });
window._doRouterReplace = (path) => router.replace({ path });

export default router;
