import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { sync } from 'vuex-router-sync'

import api from './request'
Vue.prototype.$api = api;
import utils from './utils';
Vue.prototype.$utils = utils;

Vue.filter('dateFormat', function (date, pattern = "YYYY-MM-DD") {
  return utils.dateTime(date, pattern);
});
Vue.filter('dateTime', function (date, pattern = "YYYY-MM-DD") {
  return utils.dateTime(date, pattern);
});

import { Dialog, Empty, Toast, Lazyload, Notify } from "vant";
Vue.use(Empty);
Vue.use(Dialog);
Toast.setDefaultOptions('loading', { duration: 0, forbidClick: true, loadingType: "spinner" });
Vue.use(Toast);
Vue.use(Lazyload, { lazyComponent: true });
Vue.use(Notify);
Vue.config.productionTip = false

sync(store, router)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
