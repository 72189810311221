import axios from 'axios'
import { Toast, Notify } from "vant";
const instance = axios.create({
  baseURL: process.env.BASE_API,
  withCredentials: true,
  headers: { 'Content-Type': 'application/json;charset=utf-8' }
});
instance.interceptors.request.use(config => {
  config.headers['x-zzdxs-wcid'] = localStorage.getItem('zzdxs.wcid');
  return config;
}, error => {
  return Promise.reject(error);
})
//响应拦截器
instance.interceptors.response.use(response => {
  if (response.data.code === 'NO OPENID') {
    window.location.replace(`${process.env.BASE_API}/oauth?redirect_url=${encodeURIComponent(window.location.href)}`);
    return Promise.reject(new Error(response.data.msg));
  }
  return response;
}, error => {
  Notify({ type: "danger", message: error.message });
  return Promise.reject(error);
})
// 封装get方法
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    instance.get(url, { params }).then(response => {
      resolve(response.data);
    }).catch(err => {
      reject(err)
    })
  })
}
// 封装post方法
export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    instance.post(url, data).then(response => {
      resolve(response.data);
    }).catch(err => {
      reject(err)
    });
  })
}
export function doAPI(url, Data = {}) {
  return post("/api" + url, Data);
}
export function doAPI2(url, Data = {}) {
  return post(url, Data);
}
export default {
  get, post, doAPI, doAPI2,
};