import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
moment.locale('zh_cn');

function dateTime(date, pattern = "YYYY-MM-DD") {
  date = moment(date);
  if (date.isValid()) return date.format(pattern);
  return "";
}
function uuid() {
  let uuid = uuidv4().replace(/-/g, '');
  return uuid;
}
function sleep(ts = 1000) {
  return new Promise(resolve => {
    setTimeout(resolve, ts);
  });
}
export default {
  dateTime,
  uuid,
  sleep,
};