<template>
  <div class="match-sign" v-if="fetched">
    <div class="main" v-if="agree">
      <van-steps :active="step">
        <van-step>选择线路</van-step>
        <van-step>填写报名信息</van-step>
        <van-step>预览并缴费</van-step>
        <van-step>报名完成</van-step>
      </van-steps>
      <div class="step" v-if="step === 0">
        <van-radio-group v-model="team.lineid">
          <van-cell-group>
            <van-cell v-for="line in match_lines" :key="line._id" @click="chooseLine(line)" :clickable="!line.full">
              <template #title>
                <span class="custom-title">{{ line.name }}</span>
                <van-tag v-if="line.full" type="danger">名额已满</van-tag>
                <template v-if="line.debug && line.sign_count > 0">
                  <van-tag plain type="primary">
                    报名:{{ line.sign_count }}
                  </van-tag>
                  <van-tag plain type="success">
                    支付:{{ line.pay_count }}
                  </van-tag>
                </template>
              </template>
              <template #right-icon>
                <van-radio :name="line._id" :disabled="line.full" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        <div class="step-flex">
          <van-button type="primary" @click="gotosignform" block :disabled="!team.lineid">
            下一步
          </van-button>
        </div>
      </div>
      <div class="step" v-if="step === 1">
        <div class="group">
          <div class="title">队伍名称</div>
          <van-field v-model="team.name" placeholder="填写队伍名称">
            <template #label>
              队伍名称 <span style="color:red;">*</span>
            </template>
          </van-field>
          <div class="h-flex">
            <div class="title">队伍成员</div>
            <van-button type="primary" size="small" @click="addPlayer" style="margin-right: 10px;">添加成员</van-button>
          </div>
          <van-cell-group inset>
            <van-cell v-for="(player, index) in team.players" :key="index"
              :title="player.name + ` ` + (index === 0 ? `(队长)` : `(队员${index})`)" is-link :value="player.mobile"
              @click="editPlayer(index)" />
          </van-cell-group>
        </div>
        <van-popup v-model="playerModal" :style="{ padding: '10px', width: '80%' }">
          <div class="title">成员信息</div>
          <van-form>
            <van-field v-model="player.name" placeholder="输入姓名">
              <template #label>
                姓名 <span style="color:red;">*</span>
              </template>
            </van-field>
            <van-field v-model="player.nation" placeholder="输入民族">
              <template #label>
                民族 <span style="color:red;">*</span>
              </template>
            </van-field>
            <van-field readonly clickable :value="player.sex" placeholder="选择性别" @click="showColumn('sex')">
              <template #label>
                性别 <span style="color:red;">*</span>
              </template>
            </van-field>
            <van-field v-model="player.mobile" type="number" placeholder="输入手机号">
              <template #label>
                手机号 <span style="color:red;">*</span>
              </template>
            </van-field>
            <van-field v-model="player.certificate" placeholder="输入身份证号">
              <template #label>
                身份证号 <span style="color:red;">*</span>
              </template>
            </van-field>
            <van-field readonly clickable :value="player.clothes_size" placeholder="选择T恤尺寸"
              @click="showColumn('clothes_size')">
              <template #label>
                T恤尺寸 <span style="color:red;">*</span>
              </template>
            </van-field>
            <van-field v-model="player.email" type="email" placeholder="输入邮箱">
              <template #label>
                邮箱
              </template>
            </van-field>
            <div style="margin: 10px;">
              <van-button type="primary" :style="{ width: '100%' }" @click="confirmPlayer">
                提交
              </van-button>
              <div style="width:100%;text-align:center;margin-top:20px;">
                <a v-if="player._id" style="color:red" @click="removePlayer">
                  删除
                </a>
                <a v-else style="color:gray" @click="() => playerModal = false">
                  取消
                </a>
              </div>
            </div>
          </van-form>
        </van-popup>
        <div class="step-flex">
          <van-button type="default" @click="() => step--">返回</van-button>
          <van-button style="margin-left: 10px" block type="primary" @click="gotoconfirm">
            下一步
          </van-button>
        </div>
        <van-popup v-model="columnModal" position="bottom">
          <van-picker show-toolbar :columns="columns" :default-index="columnIndex" @confirm="confirmColumn"
            @cancel="columnModal = false" />
        </van-popup>
      </div>
      <div class="step" v-if="step === 2">
        <div class="list">
          <van-cell-group inset>
            <van-cell title="所选线路" :value="linename" />
            <van-cell title="队伍名称" :value="team.name" />
            <van-cell v-for="(player, index) in team.players" :key="index"
              :title="player.name + ` ` + (index === 0 ? `(队长)` : `(队员${index})`)" :value="player.mobile" />
          </van-cell-group>
          <div class="fee">
            报名费：<span style="font-size: 24px; color:red; font-weight: 600;">{{ sign_fee }}元</span>
          </div>
        </div>
        <div class="step-flex">
          <template v-if="team._id">
            <van-button type="primary" @click="requestPay">
              缴费
            </van-button>
          </template>
          <template v-else>
            <van-button type="default" @click="() => step--">返回</van-button>
            <van-button type="primary" @click="signPay">
              确认并缴费
            </van-button>
          </template>
        </div>
      </div>
      <div class="step" v-if="step === 3">
        <div style="text-align: center; padding:30px 10px;"><strong>恭喜，报名完成</strong></div>
        <van-empty class="custom-image" image="https://scdn.xidong360.com/uploads/zzty/signsuccess.svg" description="">

        </van-empty>
      </div>
    </div>
    <template v-else>
      <article>
        <p class="title">
          <strong>免责声明</strong>
        </p>
        <p v-for="(item, index) in disclaimer" :key="index">
          <strong v-if="index === 0"> {{ item }} </strong>
          <template v-else> {{ item }} </template>
        </p>
      </article>
      <div class="agree">
        <van-button type="default" @click="doAgree('no')">不同意</van-button>
        <van-button type="primary" @click="doAgree('yes')">同意</van-button>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from "vue";
import {
  Button,
  RadioGroup,
  Radio,
  Form,
  Field,
  Calendar,
  Popup,
  Picker,
  DatetimePicker,
  Empty,
  Tag,
  Step,
  Steps
} from "vant";

Vue.use(Button);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Form);
Vue.use(Field);
Vue.use(Calendar);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(DatetimePicker);
Vue.use(Empty);
Vue.use(Tag);
Vue.use(Step);
Vue.use(Steps);
import _ from "underscore";
export default {
  name: "matchSign",
  data() {
    return {
      fetched: false,
      agree: false,
      step: 0,
      match: {},
      disclaimer: [
        "本人已经阅读本责任免除书，已明白无误地知悉、理解、掌握本责任免除书的全部内容和含义，完全接受本责任免除书中规定的条件。",
        "一、本人及所在团队的队员在报名时向组织者出示的相关证明及填写的信息，保证其真实合法有效，保证无任何隐瞒，并愿意承担信息错误所带来的一切后果。",
        "二、本次赛事活动是对技能要求非常高及要求具备良好身心健康状况的赛事，本人已充分了解比赛的过程中存在潜在的风险，并可能由此导致伤残、损失甚至死亡，我会竭尽所能，以对自己安全负责的态度参赛。",
        "三、赛事期间，本人服从组织者的活动安排，凡因个人私自行动而造成的一切后果，由本人承担完全责任。",
        "四、赛事期间，凡因个人隐瞒实际情况（病史、身体状况及其他状况）所造成的一切后果责任自负。",
        "五、必须尊重当地民族宗教等风俗习惯，由于本人原因造成的与当地居民的纠纷、冲突等，一切后果自负。",
        "六、我本人以及我的继承人、代理人、个人代表和亲属均放弃对赛事组委会及赛事关联方追究因参赛导致我本人伤残、损失或死亡之相关的法律责任的权利。",
        "七、本人同意本次赛事组织方在全球范围内永久性地发表、使用、修改和开发任何同本次赛事有关的本人或有本人出现的信息和材料（包括照片、录像和影片），组织方有权对上述信息和材料进行进一步转授和许可。这些信息和材料使用无需向本人进行提前通知，或提供额外的补偿，而且本人放弃所有检查或批准的权利。",
        "八、本人是本参赛团队的队长，本人的签字即代表本人所在的团队成员对本文件内容的认可。"
      ],
      match_lines: [
        { _id: "1", name: "定向人生", full: false },
        { _id: "2", name: "郑在趣玩", full: false },
        { _id: "3", name: "活力之城", full: false },
        { _id: "4", name: "豫见古今", full: false },
        { _id: "5", name: "一路童行", ischild: true, fall: false },
        { _id: "6", name: "铁马英雄", fall: false },
      ],
      team: {
        _id: "",
        lineid: "1",
        name: "双击666队",
        players: [],
      },
      player_template: {
        _id: "",
        name: "",
        nation: "",
        sex: "",
        mobile: "",
        certificate: "",
        clothes_size: "",
        email: "",
      },
      player: {},
      playerModal: false,
      column: "",
      columns: [],
      columnIndex: -1,
      columnModal: false,
      shareinfo: "",
    };
  },
  computed: {
    linename() {
      return _.findWhere(this.lines, { _id: this.team.lineid })?.name;
    },
    sign_fee() {
      return this.team.players.length * Number(this.match.sign_fee);
    },
  },
  mounted() {
    console.log('route', this.$route);
    if (this.$route.query.wcid) this.$router.replace({ path: this.$route.path });
    this.getdata();
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "" });
      let signdiscountid = localStorage.getItem("zzdxs.signdiscountid");
      this.$api.doAPI("/sign/info", { signdiscountid }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.match = res.data.match;
          this.match_lines = res.data.match.match_lines;
          this.disclaimer = res.data.match.disclaimer;
          this.makeShare(res.data.shareInfo);
          if (res.data.team._id) {//已报名
            this.team = res.data.team;
            this.agree = true;
            if (res.data.team.paystate === 1) {//已缴费
              this.step = 3;
            } else {
              this.step = 2;
            }
          } else {
            try {
              let team = localStorage.getItem("zzdxs.team");
              if (team) {
                team = JSON.parse(team);
                this.team = team;
              } else {
                this.team = res.data.team;
              }
            } catch (e) {

            }
          }
          this.fetched = true;
        } else {
          this.$notify({ type: "danger", message: res.msg });
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    chooseLine(line) {
      if (line.full) {
        return this.$notify({ type: "danger", message: "名额已满" });
      }
      this.team.lineid = line._id;
    },
    gotosignform() {
      if (!this.team.lineid) {
        return this.$notify({ type: 'danger', message: "选择路线" });
      }
      this.step++;
    },
    addPlayer() {
      this.player = _.extend({}, { ...this.player_template, });
      this.playerModal = true;
    },
    editPlayer(index) {
      this.player = _.extend({}, { ...this.team.players[index] });
      this.playerModal = true;
    },
    showColumn(column) {
      this.column = column;
      switch (column) {
        case "sex":
          this.columns = ["男", "女"];
          break;
        case "clothes_size":
          this.columns = ["S", "M", "L", "XL", "XXL", "XXXL", "XXXXL"];
          break;
      }
      this.columnIndex = this.columns.indexOf(this.player[column]);
      this.columnModal = true;
    },
    confirmColumn(value) {
      this.player[this.column] = value;
      this.columnModal = false;
    },
    confirmPlayer() {
      if (!this.player.name) return this.$notify({ type: 'danger', message: "姓名不能为空" });
      if (!this.player.nation) return this.$notify({ type: 'danger', message: "民族不能为空" });
      if (!this.player.sex) return this.$notify({ type: 'danger', message: "性别不能为空" });
      if (!this.player.mobile) return this.$notify({ type: 'danger', message: "手机号不能为空" });
      if (!this.player.certificate) return this.$notify({ type: 'danger', message: "身份证号不能为空" });
      if (!this.player.clothes_size) return this.$notify({ type: 'danger', message: "T恤尺寸不能为空" });
      if (this.player._id) {
        let index = this.team.players.findIndex(n => n._id === this.player._id);
        this.team.players[index] = { ...this.player };
      } else {
        this.team.players.push({ ...this.player, _id: this.$utils.uuid() });
      }
      localStorage.setItem("zzdxs.team", JSON.stringify(this.team));
      this.playerModal = false;
    },
    removePlayer() {
      this.team.players = this.team.players.filter(n => n._id !== this.player._id);
      localStorage.setItem("zzdxs.team", JSON.stringify(this.team));
      this.playerModal = false;
    },
    gotoconfirm() {
      if (this.team.players.length === 0) {
        return this.$notify({ type: 'danger', message: "队伍成员不能为空" });
      }
      let line = _.findWhere(this.match_lines, { _id: this.team.lineid });
      if(line.checkmethod === 'checkplayers1') {
        if (this.team.players.length !== 5) return this.$notify({ type: 'danger', message: "报名人数不符：要求5人" });
      } else if (line.checkmethod === 'checkplayers2') {
        if (this.team.players.length < 2 || this.team.players.length > 4) return this.$notify({ type: 'danger', message: "报名人数不符：要求2-4人" });
      } else if (line.checkmethod === 'checkplayers3') {
        if (this.team.players.length !== 3) return this.$notify({ type: 'danger', message: "报名人数不符：要求3人" });
      } else if (line.checkmethod === 'checkplayers4') {
        if (this.team.players.length !== 2) return this.$notify({ type: 'danger', message: "报名人数不符：要求2人" });
      }
      this.step++;
    },
    signPay() {
      this.$toast.loading({ message: "" });
      let signdiscountid = localStorage.getItem("zzdxs.signdiscountid");
      this.$api.doAPI("/sign/confirm", { ...this.team, signdiscountid }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.team = res.data.team;
          localStorage.removeItem("zzdxs.team");
          this.requestPay();
        } else {
          this.$notify({ type: "danger", message: res.msg });
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    requestPay() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/sign/pay", { teamid: this.team._id }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          window.WeixinJSBridge.invoke('getBrandWCPayRequest', res.data.payargs, (res) => {
            if (res.err_msg === "get_brand_wcpay_request:ok") {
              this.$notify({ type: "success", message: "支付成功" });
              this.getdata();
            } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
              this.$notify({ type: "danger", message: "支付取消" });
            } else if (res.err_msg === "get_brand_wcpay_request:fail") {
              this.$notify({ type: "danger", message: "支付失败" });
            } else {
              this.$notify({ type: "danger", message: "支付失败" });
            }
          });
        } else {
          this.$notify({ type: "danger", message: res.msg });
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    makeShare(shareInfo) {
      if (!shareInfo) return;
      this.$api.doAPI2("/jssdk", {
        urls: _.uniq([
          `https://zzdxs.${process.env.BASE_DOMAIN}/`,
          `https://zzdxs.${process.env.BASE_DOMAIN}`,
          `https://zzdxs.${process.env.BASE_DOMAIN}/sign/`,
          `https://zzdxs.${process.env.BASE_DOMAIN}/sign`,
          // `https://zzdxs.${process.env.BASE_DOMAIN}${this.$route.fullPath}`.split('#')[0],
          // location.href.split('#')[0],
          `https://zzdxs.${process.env.BASE_DOMAIN}/match/`,
          `https://zzdxs.${process.env.BASE_DOMAIN}/match`,
        ])
      }).then(async ({ debug, jsApiList, list }) => {
        for (let i = 0; i < list.length; i++) {
          let { url, config } = list[i];
          let configresult = await this.configShare({ debug, jsApiList, ...config });
          if (configresult) {
            shareInfo = { ...shareInfo };
            this.shareinfo = JSON.stringify(shareInfo);
            window.wx.updateAppMessageShareData({
              ...shareInfo,
              success: () => {
              },
            });
            window.wx.updateTimelineShareData({
              ...shareInfo,
              success: () => {
              },
            });
            window.wx.onMenuShareAppMessage({
              ...shareInfo,
              success: () => {
              }
            });
            window.wx.onMenuShareTimeline({
              ...shareInfo,
              success: () => {
              }
            });
            break;
          }
          await this.$utils.sleep(500);
        }
      }).catch((err) => {
        console.error(err);
      });
    },
    configShare(config) {
      return new Promise(resolve => {
        window.wx.config(config);
        window.wx.ready(function () {
          console.log(`[success] ${Date.now()}`);
          resolve(true);
        });
        window.wx.error(function (res) {
          console.log(`[error] ${Date.now()}`, res);
          resolve(false);
        });
      });
    },
    doAgree(state) {
      if (state === "yes") {
        this.agree = true;
      }
      if (state === "no") {
        this.$router.go("-1");
      }
    },
  },
};
</script>
<style lang="less">
.match-sign {
  .main {
    .step {
      p {
        padding: 10px;
      }

      .title {
        padding: 10px;
        color: #999;
      }

      .h-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
      }

      .step-flex {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
        margin: 0 10px;

        button {
          flex: 1;
        }
      }

      .custom-image .van-empty__image {
        width: 100px;
        height: 100px;
      }

      .fee {
        text-align: center;
        padding: 10px 20px;
      }

      .custom-title {
        margin-right: 4px;
        vertical-align: middle;
      }
    }
  }

  article {
    padding: 10px;

    p {
      text-indent: 2rem;
    }

    .title {
      text-align: center;
      font-size: 1.2rem;
      text-indent: 0;
    }
  }

  .agree {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    button {
      width: 50%;
    }
  }

  .van-empty__bottom {
    width: 80%;
  }

  .van-cell-group--inset {
    margin: 0;
  }
}
</style>