<template>
  <div class="match-detail">
    <div class="main">
      <div class="head">
        <van-image :src="match.cover" fit="cover" />
        <div class="name">{{ match.name }}</div>
      </div>
      <div class="basic">
        <van-cell icon="/images/signtime.png" title="报名截止" :value="match.signtime" />
        <van-cell icon="/images/fee.png" title="报名费用" :value="match.fee" />
        <van-cell icon="/images/matchtime.png" title="比赛时间" :value="match.matchtime" />
        <van-cell icon="/images/address.png" title="赛事地点" :value="match.address" is-link @click="goGuide" />
      </div>
      <div class="match">
        <div class="header">赛事线路</div>
        <div class="groups">
          <div class="group" v-for="line in match_lines" :key="line._id" @click="showIdea(line)">
            <div class="title">{{ line.name }}</div>
            <div class="desc">{{ line.desc }}</div>
          </div>
        </div>
        <div class="header">赛事规程</div>
        <div class="content">
          <article v-html="match.guicheng"></article>
        </div>
      </div>
    </div>
    <van-goods-action>
      <van-goods-action-icon icon="/images/share.png" text="分享" @click="doShare" />
      <van-goods-action-button type="info" text="立即报名" @click="toSign" />
    </van-goods-action>
    <van-dialog v-model="visible" :title="linename" confirmButtonText="关闭">
      <div class="lineIdea" v-html="linecontent">
      </div>
    </van-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import {
  NavBar,
  Cell,
  CellGroup,
  Tab,
  Tabs,
  Icon,
  Image,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton,
} from "vant";

Vue.use(NavBar);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Icon);
Vue.use(Image);
Vue.use(GoodsAction);
Vue.use(GoodsActionButton);
Vue.use(GoodsActionIcon);
import _ from "underscore";
export default {
  name: "matchDetail",
  data() {
    return {
      match: {
        cover: '/images/cover.png',
        name: '2024年河南省全民健身大赛郑州坐标·河南省城市定向系列赛',
        signtime: '',
        fee: '60元/人',
        matchtime: '',
        address: '郑东新区市民体育公园',
        guicheng: '“城市坐标定向系列赛是具有前沿、时尚和消费引领的自主品牌赛事，至今已成功举办了五届。”贾占波介绍，该赛事以整个市区为赛场，集健身、文化、旅游、教育、环保等多种元素于一体，理念新颖、趣味性强、参与度高，深受广大群众喜爱。参赛的过程是一个发现城市之美、感受城市变化、宣传提升城市形象、传递正能量的过程，体现了体育多元化的社会功能，赛事的筹办充分体现了“政府主导、体育部门负责、各相关部门协同、全社会共同参与”的全民健身工作格局。'
      },
      match_lines: [],
      linename: "",
      linecontent: "",
      visible: false,
      shareinfo: "",
      center: {
        latitude: 34.758376,
        longitude: 113.764763,
      },
      lat: 0,
      lng: 0,
    };
  },
  mounted() {
    console.log('route', this.$route);
    if (this.$route.query.wcid) this.$router.replace({ path: this.$route.path });
    this.getdata();
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/match", { matchid: "1" }).then((res) => {
        this.$toast.clear();
        if (res.code === 0) {
          this.match = res.data.match;
          this.match_lines = res.data.match.match_lines;
          this.makeShare(res.data.shareInfo);
        } else {
          this.$dialog.alert({ title: "提示", message: res.msg });
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    makeShare(shareInfo) {
      if (!shareInfo) return;
      this.$api.doAPI2("/jssdk", {
        urls: _.uniq([
          `https://zzdxs.${process.env.BASE_DOMAIN}/`,
          `https://zzdxs.${process.env.BASE_DOMAIN}`,
          `https://zzdxs.${process.env.BASE_DOMAIN}/match/`,
          `https://zzdxs.${process.env.BASE_DOMAIN}/match`,
          `https://zzdxs.${process.env.BASE_DOMAIN}/sign/`,
          `https://zzdxs.${process.env.BASE_DOMAIN}/sign`,
        ])
      }).then(async ({ debug, jsApiList, list }) => {
        for (let i = 0; i < list.length; i++) {
          let { url, config } = list[i];
          let configresult = await this.configShare({ debug, jsApiList, ...config });
          if (configresult) {
            shareInfo = { ...shareInfo };
            this.shareinfo = JSON.stringify(shareInfo);
            window.wx.updateAppMessageShareData({
              ...shareInfo,
              success: () => {
              },
            });
            window.wx.updateTimelineShareData({
              ...shareInfo,
              success: () => {
              },
            });
            window.wx.onMenuShareAppMessage({
              ...shareInfo,
              success: () => {
              }
            });
            window.wx.onMenuShareTimeline({
              ...shareInfo,
              success: () => {
              }
            });
            break;
          }
          await this.$utils.sleep(500);
        }
      }).catch((err) => {
        console.error(err);
      });
    },
    configShare(config) {
      return new Promise(resolve => {
        window.wx.config(config);
        window.wx.ready(function () {
          console.log(`[success] ${Date.now()}`);
          resolve(true);
        });
        window.wx.error(function (res) {
          console.log(`[error] ${Date.now()}`, res);
          resolve(false);
        });
      });
    },
    doShare() {
      // this.$toast.loading({ message: "" });
    },
    showIdea(line) {
      this.linename = line.name;
      this.linecontent = line.content;
      this.visible = true;
    },
    handleOk() {
      this.visible = false;
    },
    toSign() {
      // this.$router.push({ path: `/sign/` });
      window.location.href = '/sign/';
    },
    ///导航
    goGuide() {
      window.location.href = `http://apis.map.qq.com/uri/v1/marker?marker=coord:${this.center.latitude},${this.center.longitude};title:${this.match.address};addr:${this.match.address}`
    }
  },
};
</script>
<style lang="less">
.match-detail {
  .main {
    margin-bottom: 60px;

    .match {
      .fx {
        text-align: center;
      }
    }

    .content {
      padding: 0 15px;
      color: #333;
      font-size: 0.9rem;
    }
  }

  .head {
    position: relative;

    .name {
      position: absolute;
      bottom: 0;
      background: #999;
      left: 0;
      right: 0;
      opacity: 0.9;
      padding: 10px 5px;
      font-size: 16px;
      color: #fff;
    }
  }

  .van-nav-bar .van-icon {
    color: #000;
  }

  .van-cell {
    padding: 10px;
  }

  .header {
    padding: 10px;
    font-weight: 600;
  }

  .groups {
    width: 100%;
    overflow: auto;
    display: -webkit-box;

    .group {
      background-color: #FF7F02;
      color: #fff;
      margin: 10px;
      padding: 10px 15px;
      width: 180px;
      font-size: 12px;
      border-radius: 5px;

      .title {
        font-size: 16px;
        font-weight: 600;
        padding-bottom: 10px;
      }
    }
  }

  article {
    width: 100%;

    span {
      width: 100%;
    }

    img {
      width: 100%;
    }
  }

  .lineIdea {
    padding: 20px 10px;
  }
}
</style>